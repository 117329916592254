import { EstateImageObject, EstateObject } from '../../models/estate-object.model';

export interface CurrentEstateState {
  currentEstate: EstateObject;
  currentEstateImages: EstateImageObject[];
  currentEstateThumbs: EstateImageObject[];
  currentEstateMaklerProfile?: any;
  currentEstateMaklerPhoto: string;
  currentEstateRundgangLink: string;
  error: string;
  isLoading: boolean;
  isLoadingSuccess: boolean;
  isLoadingFailure: boolean;
}

export class CurrentEstateInitState implements CurrentEstateState {
  currentEstate: EstateObject;
  currentEstateImages: EstateImageObject[];
  currentEstateThumbs: EstateImageObject[];
  currentEstateMaklerProfile?: any;
  currentEstateMaklerPhoto: string;
  currentEstateRundgangLink: string;
  error: string;
  isLoading: boolean;
  isLoadingSuccess: boolean;
  isLoadingFailure: boolean;
  constructor() {
    this.currentEstate = {} as EstateObject;
    this.currentEstateImages = [];
    this.currentEstateThumbs = [];
    this.currentEstateMaklerProfile = null;
    this.currentEstateMaklerPhoto = null;
    this.currentEstateRundgangLink = null;
    this.isLoading = false;
    this.isLoadingSuccess = false;
    this.isLoadingFailure = false;
  }
}
export const initialState = new CurrentEstateInitState();
