import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideRouter } from '@angular/router';
import { provideLottieOptions } from 'ngx-lottie';
import player from 'lottie-web';
import { routes } from './app.routes';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient, provideHttpClient, withFetch } from '@angular/common/http';
import { StoreModule } from '@ngrx/store';
import { StoreRouterConnectingModule, routerReducer } from '@ngrx/router-store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { estatesReducer } from './offers/store/estates.reducer';
import { EstatesEffects } from './offers/store/estates.effects';
import { CurrentEstateEffects } from './offers/store/current-estate/current-estate.effects';
import { currentEstateReducer } from './offers/store/current-estate/current-estate.reducer';

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideHttpClient(withFetch()),
    importProvidersFrom(
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
        }
      })
    ),
    importProvidersFrom(StoreModule.forRoot()),
    importProvidersFrom(StoreModule.forFeature('router', routerReducer)),
    importProvidersFrom(StoreModule.forFeature('estates', estatesReducer)),
    importProvidersFrom(StoreModule.forFeature('currentEstate', currentEstateReducer)),

    importProvidersFrom(EffectsModule.forFeature([EstatesEffects])),
    importProvidersFrom(EffectsModule.forFeature([CurrentEstateEffects])),

    importProvidersFrom(StoreRouterConnectingModule.forRoot()),
    importProvidersFrom(EffectsModule.forRoot()),
    importProvidersFrom(
      StoreDevtoolsModule.instrument({
        maxAge: 25, // Retains last 25 states
        connectInZone: true
      })
    ),

    provideLottieOptions({
      player: () => player
    })
  ]
};
